<template>
	<div class="container-fluid" :class="theme" ref="container" :style="{background: theme=='night' ? '#333' : '#fff', color: theme=='night' ? '#fff' : null}"
		style="position: absolute; left: 0; top: 0; z-index: 50; min-height: 100vh">
		<Toast />

		<div class="clearfix" style="padding: 6px; border-bottom: 1px solid rgb(129 129 129)" :style="{'border-bottom': theme=='night' ? '1px solid rgb(129 129 129)' : '1px solid #ccc'}">
			<div>
				<div  @click="changeTheme" style="padding-left: 10px; float: right; padding: 5px; width: 26px; text-align: center; cursor: pointer;">
					<i class="fa" :class="{'fa-sun': theme=='night', 'fa-moon': theme=='day'}"></i>
				</div>
				<div style="float: right; padding-top: 3px; padding-right: 12px;">
					<InputSwitch @change="togglePresent" v-model="isPresent" />
				</div>
				<div style="float: right; padding-top: 3px; padding-left: 10px;">
					<span style="font-weight: bold; font-size: 20px;">Present: &nbsp; </span>
				</div>

				<div v-if="isPresent" class="pull-right">
					<router-link to="/radiologist/reports/pending" class="btn"
						:class="{ 'btn-secondary': !openPoolCount, 'btn-info': openPoolCount > 0 }" style="margin-right: 5px;">
						System Pool ({{ openPoolCount }})
					</router-link>
					<router-link to="/radiologist/reports/specific" class="btn"
						:class="{ 'btn-secondary': !directPoolCount, 'btn-success': directPoolCount > 0 }"
						style="margin-right: 5px">
						Direct Pool ({{ directPoolCount }})
					</router-link>
					<router-link to="/radiologist/reports/review" class="btn"
						:class="{ 'btn-secondary': !reviewPoolCount, 'btn-warning': reviewPoolCount > 0 }"
						style="margin-right: 5px">
						Review Pool ({{ reviewPoolCount }})
					</router-link>

				</div>

				<h4 style="font-weight: bold;">
					<router-link to="/radiologist/dashboard"><i class="fa fa-home"></i></router-link>
					Report Desk ({{ reported }})
				</h4>
			</div>
		</div>

		<template v-if="!limitReached && isPresent">

			<template v-if="state == 'accepting' || state == 'reporting'">

				<div style="padding: 10px 0;" class="clearfix">
					<div class="btn-group pull-left clearfix">
						<button @click="position = 'left'; drawImage();" type="button" class="btn btn-info">
							<i class="fa fa-toggle-left"></i>
						</button>
						<button @click="position = 'middle'; drawImage();" type="button" class="btn btn-info">
							<i class="fa fa-align-justify"></i>
						</button>
						<button @click="position = 'right'; drawImage();" type="button" class="btn btn-info">
							<i class="fa fa-toggle-right"></i>
						</button>
						<a v-if="false" target="_blank" class="btn btn-warning" :href="apiOrigin + '/image-viewer/' + report?.id">
							<i class="fa fa-image"></i>
						</a>
					</div>
					<div class="pull-left" style="padding: 0 8px">
						<div>
							<input type="range" min="-128" max="128" v-model="brightness" @change="drawImage"
								style="width: 200px;">
						</div>
						<div>
							<input type="range" min="-128" max="128" v-model="contrast" @change="drawImage"
								style="width: 200px;">
						</div>
					</div>

					<div v-if="state == 'reporting'" class="pull-right">
						<AutoComplete v-model="template" :dropdown="true" placeholder="Select Template"
							:suggestions="filteredTemplates" @item-select="selectTemplate" @complete="searchTemplates($event)"
							field="title" />
					</div>

					<button @click="showNotesEditor = !showNotesEditor" type="button" class="btn btn-primary pull-right"
						title="Notes" style="margin-right: 10px"><i class="fa fa-sticky-note"></i></button>

					<span class="pull-right" style="margin-top: 8px; padding-right: 15px; font-weight: bold;">
						<span v-if="reportType == 'open'" class="text-info">System Pool</span>
						<span v-if="reportType == 'direct'" class="text-success">Direct Pool</span>
						<span v-if="reportType == 'review'" class="text-warning">Review Pool</span>
					</span>

					<div style="margin-left: 340px; margin-right: 255px;">

						{{ report?.id }} <b>|</b> {{ report?.patient_name }} <b>|</b> {{ report?.patient_age }} <b>|</b> <span
							style="text-transform: capitalize;">{{ report?.patient_gender }}</span> <b>| </b>
						<b>{{ report?.test.name }}</b>


						<div>
							{{ report?.history }}
							<span v-if="report?.review_notes"> <b>|</b> {{ report?.review_notes }}</span>
						</div>

						<div>
							<span v-if="historyAudioUrl">
								History: <audio :src="historyAudioUrl" controls></audio>
								&nbsp; &nbsp;
							</span>
							<span v-if="reviewAudioUrl">
								Review: <audio :src="reviewAudioUrl" controls></audio>
							</span>
						</div>
					</div>


				</div>

				<div class="clearfix"></div>
				<div class="row">
					<div :style="{ display: position == 'left' ? 'none' : 'block' }"
						:class="{ 'col-sm-6': position == 'middle', 'col-sm-12': position == 'right' }">
						<div style="width: 80px; float: left;">
							<div style="margin-bottom: 4px;" v-for="image in allImages" :key="image">
								<img
									@click="showImage = image; brightness = 0; contrast = 0; lastZoomLevel = 0; lastZoomX = 0; lastZoomY = 0; zoomed=true; drawImage();"
									style="max-width: 100%; cursor: pointer;" class="border"
									:class="{ 'border-info': showImage != image, 'border-danger': showImage == image }"
									:src="apiOrigin + '/images/radiology/' + image" alt="">
							</div>
						</div>
						<div style="margin-left: 90px;">
							<img v-if="showImage && imageLoading" style="display: block; margin: auto; max-width: 100%"
								:src="apiOrigin + '/images/radiology/' + showImage" alt="">
							<div id="canvasContainer">
								<canvas id="mainCanvas" width="0" height="0" style="display: block; margin: auto;"
									@wheel="onWheel" @mousedown="isMouseDown = true"></canvas>
							</div>

						</div>
					</div>
					<div :style="{ display: position == 'right' ? 'none' : 'block' }"
						:class="{ 'col-sm-6': position == 'middle', 'col-sm-12': position == 'left' }">

						<template v-if="state == 'reporting'">

							<template v-if="!showNotesEditor">
								<div style="border-bottom: none; padding: 5px 9px; border-top-left-radius: 5px; border-top-right-radius: 5px; text-align: center;" :style="{border: theme == 'night' ? '1px solid rgb(129 129 129)' : '1px solid #c4c4c4'}">
									<b>Findings</b>
								</div>
								<ckeditor style="min-height: 180px; border: 1px solid #c4c4c4; border-top: none;" :editor="editor" :style="{background: theme=='night' ? '#222' : '#fff', color: theme=='night' ? '#fff' : null}"
									:config="editorConfig" v-model="editorData" @ready="onReady"></ckeditor>

								<div style="height: 8px"></div>
								
								<div style="border-bottom: none; padding: 5px 9px; border-top-left-radius: 5px; border-top-right-radius: 5px; text-align: center;" :style="{border: theme == 'night' ? '1px solid rgb(129 129 129)' : '1px solid #c4c4c4'}">
									<b>Impression</b>
								</div>
								<ckeditor style="min-height: 180px; border: 1px solid #c4c4c4; border-top: none;" :editor="editor" :style="{background: theme=='night' ? '#222' : '#fff', color: theme=='night' ? '#fff' : null}"
									:config="editorConfig" v-model="impression" @ready="onReady"></ckeditor>

									

								<div style="padding: 10px 0; padding-bottom: 30px;" class="clearfix">
									<div class="pull-right">
										<button @click="publishReport" type="button" class="btn btn-success btn-lg">
											<i class="fa fa-paper-plane"></i> Check &amp; Publish
											<span v-if="state == 'reporting'"> ({{ reportTimeoutText }})</span>
											<!-- reportType == 'open' &&  -->
										</button>
									</div>
									<button @click="draftReport" style="margin-right: 10px;" type="button"
										class="btn btn-primary pull-left">
										Save Draft
									</button>
									<button @click="saveTemplate" style="margin-right: 10px;" type="button"
										class="btn btn-info pull-left">
										Save As Template
									</button>
									<button v-if="reportType == 'open' && false" type="button" class="btn btn-danger pull-left"
										style="margin-left: 10px;" @click="releaseReport">Release</button>
								</div>
							</template>

							<template v-if="showNotesEditor">

								<div style="min-height: 400px;">
									<textarea v-model="notes" class="form-control" rows="5" placeholder="Notes"></textarea>
								</div>

								<div style="padding: 10px 0; padding-bottom: 30px;" class="clearfix">
									<button type="button" class="btn btn-lg btn-success pull-right" @click="saveNotes">
										Save Notes
										<span v-if="state == 'reporting'"> ({{ reportTimeoutText }})</span>
										<!-- reportType == 'open' &&  -->
									</button>
								</div>

							</template>
						</template>
						<template v-if="state == 'accepting'">
							<div>
								<div style="min-height: 400px;">
									<div>
										<textarea v-if="showNotesEditor" v-model="notes" class="form-control" rows="5"
											placeholder="Notes"></textarea>
									</div>


									<div v-if="true"
										style="font-size: 20px; padding-top: 180px; text-align: center; font-weight: bold;">

									</div>

								</div>
								<div style="padding: 10px 0; padding-bottom: 30px;" class="clearfix">
									<button type="button" class="btn btn-lg btn-success pull-right" @click="acceptReport">
										Accept<span v-if="acceptTimeoutText"> ({{ acceptTimeoutText }})</span>
									</button>
									<button v-if="false" type="button" class="btn btn-danger pull-left"
										@click="releaseReport">Skip</button>
								</div>
							</div>
						</template>

					</div>
				</div>
			</template>

			<template v-if="state == 'waiting'">
				<div style="font-size: 60px; padding-top: 200px; margin: auto; width: 355px; font-weight: bold;">
					{{ waitingText }}
					<div style="font-size: 15px;">Please wait. We are searching cases for you.</div>
				</div>
			</template>

			<template v-if="state == 'inactive'">
				<div style="font-size: 20px; padding-top: 200px; text-align: center; font-weight: bold;">
					We found you inactive. Please let us know when you are available again.
					<div style="margin-top: 50px;">
						<button type="button" class="btn btn-primary btn-lg" @click="makeMeAvailable">I'm Available</button>
					</div>
				</div>
			</template>

		</template>

		<template v-if="limitReached && isPresent">
			<div style="font-size: 30px; padding-top: 200px; text-align: center; font-weight: bold;">
				You can simultaneously report in maximum {{ limit }} window.
			</div>
		</template>



		<div v-if="!isPresent" style="padding: 180px 0; text-align: center;">
			<h3>You are absent</h3>
		</div>


	</div>
</template>

<script>
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import Toast from 'primevue/toast';
import AutoComplete from 'primevue/autocomplete';
import InputSwitch from 'primevue/inputswitch';

export default {
	components: {
		Toast,
		AutoComplete,
		InputSwitch
	},
	name: 'app',
	data() {
		return {
			editor: DecoupledEditor, //ClassicEditor,
			editorData: '',
			impression: '',
			editorConfig: {},
			report: null,

			theme: 'night',

			position: 'middle',

			allImages: [],
			showImage: "",
			imageLoading: true,

			brightness: 0,
			contrast: 0,

			isMouseDown: false,
			lastMousePos: null,
			drawing: false,
			lastImage: '',
			lastCanvas: null,
			lastWidth: 0,
			lastHeight: 0,
			lastBrightness: 0,
			lastContrast: 0,
			zoomed: false,
			lastZoomLevel: 0,
			lastZoomX: 0,
			lastZoomY: 0,
			lastPosition: 'middle',


			templates: [],
			filteredTemplates: [],
			template: null,

			historyAudioUrl: '',
			reviewAudioUrl: '',

			isPresent: true,

			lastActive: new Date().getTime(),
			timerIncreased: false,


			limitReached: false,
			limit: 0,

			reportId: 0,
			reportType: '',
			reportTimeout: 0,
			reportTill: 0,
			reportTimeoutText: '00 : 00',


			acceptTimeout: 0,
			acceptTill: 0,
			acceptTimeoutText: '00 : 00',

			notes: '',
			showNotesEditor: false,

			state: 'waiting', // out, inactive, waiting, accepting, reporting
			waitingSince: new Date().getTime(),
			waitingText: '00 : 00 : 00',
			waitingUpdateInterval: 0,


			openPoolCount: 0,
			directPoolCount: 0,
			reviewPoolCount: 0,
			reported: 0,

		};
	},
	methods: {
		onWheel(e) {
			if (e.ctrlKey) {
				e.preventDefault();

				const canvas = document.getElementById('mainCanvas');

				let dy = 0.1 * e.deltaY;

				var rect = canvas.getBoundingClientRect();
				let x = e.clientX - rect.left;
				let y = e.clientY - rect.top;

				let iw = this.lastCanvas.width;
				let ih = this.lastCanvas.height;

				let ozf = (100 - this.lastZoomLevel) / 100;

				let osw = Math.ceil(iw * ozf);
				let osh = Math.ceil(ih * ozf);

				let ozpx = this.lastZoomX + ((x * osw) / canvas.width)
				let ozpy = this.lastZoomY + ((y * osh) / canvas.height)

				let zl = this.lastZoomLevel + dy;
				if (zl > 90) zl = 90;
				if (zl < 0) zl = 0;
				this.lastZoomLevel = zl;

				let zf = (100 - this.lastZoomLevel) / 100;

				let sw = Math.ceil(iw * zf);
				let sh = Math.ceil(ih * zf);

				let zx = ozpx - ((x * sw) / canvas.width)
				let zy = ozpy - ((y * sh) / canvas.height)

				if (zx + sw > iw) zx = iw - sw;
				if (zy + sh > ih) zy = ih - sh;

				if (zx < 0) zx = 0;
				if (zy < 0) zy = 0;

				this.lastZoomX = zx;
				this.lastZoomY = zy;

				this.zoomed = true;
				this.drawImage();
			}
		},
		onMouseMove(e) {
			if (!this.isMouseDown) return;

			let x = e.x;
			let y = e.y;

			if (!this.lastMousePos) return this.lastMousePos = { x: x, y: y };

			const canvas = document.getElementById('mainCanvas');

			let dx = this.lastMousePos.x - x;
			let dy = this.lastMousePos.y - y;


			let iw = this.lastCanvas.width;
			let ih = this.lastCanvas.height;

			let zf = (100 - this.lastZoomLevel) / 100;

			let sw = Math.ceil(iw * zf);
			let sh = Math.ceil(ih * zf);

			dx = (dx * sw) / canvas.width;
			dy = (dy * sh) / canvas.height;

			let zx = this.lastZoomX + dx;
			let zy = this.lastZoomY + dy;

			if (zx + sw > iw) zx = iw - sw;
			if (zy + sh > ih) zy = ih - sh;

			if (zx < 0) zx = 0;
			if (zy < 0) zy = 0;

			this.lastZoomX = zx;
			this.lastZoomY = zy;

			this.lastMousePos = { x: x, y: y };
			this.zoomed = true;
			this.drawImage();
		},
		onMouseUp(){
			this.isMouseDown = false;
			this.lastMousePos = null;
		},
		drawImage() {
			if (this.drawing) return;
			this.drawing = true;

			const canvas = document.getElementById('mainCanvas');
			let ctx = canvas.getContext("2d", { willReadFrequently: true });
			if (this.showImage == this.lastImage && this.position == this.lastPosition) {

				let brightness = Number(this.brightness);
				let contrast = Number(this.contrast);

				if (brightness == this.lastBrightness && contrast == this.lastContrast && !this.zoomed) {
					this.drawing = false;
					return;
				}

				this.lastBrightness = brightness;
				this.lastContrast = contrast;
				this.zoomed = false;

				let iw = this.lastCanvas.width;
				let ih = this.lastCanvas.height;

				let zf = (100 - this.lastZoomLevel) / 100;


				let sw = Math.ceil(iw * zf);
				let sh = Math.ceil(ih * zf);

				let f = (259 * (contrast + 255)) / (255 * (259 - contrast));

				let octx = this.lastCanvas.getContext("2d", { willReadFrequently: true });
				let data = octx.getImageData(this.lastZoomX, this.lastZoomY, sw, sh);

				for (let i = 0; i < data.data.length; i += 4) {
					let c = data.data[i];
					if (contrast) c = f * (c - 128) + 128;
					if (brightness) c = c + brightness;

					if (c > 255) c = 255;
					if (c < 0) c = 0;

					data.data[i] = c;
					data.data[i + 1] = c;
					data.data[i + 2] = c;
				}


				let o2canvas = new OffscreenCanvas(sw, sh);
				let o2ctx = o2canvas.getContext("2d", { willReadFrequently: true });
				o2ctx.putImageData(data, 0, 0);

				ctx.drawImage(o2canvas, 0, 0, sw, sh, 0, 0, this.lastWidth, this.lastHeight);

				this.drawing = false;
			} else {
				const canvasContainer = document.getElementById('canvasContainer');

				if (!this.showImage || !canvasContainer || !canvas) return;

				this.lastImage = this.showImage;
				this.lastPosition = this.position;

				this.imageLoading = true;
				canvas.width = 0;
				canvas.height = 0;

				setTimeout(() => {
					let image = new Image();
					image.onload = (e => {
						let imgWidth = image.width;
						let imgHeight = image.height;

						let canvasWidth = canvasContainer.offsetWidth;
						let canvasHeight = 0;

						if (imgWidth > canvasWidth) {
							canvasHeight = Math.ceil(imgHeight * canvasWidth / imgWidth);
						} else {
							canvasWidth = imgWidth;
							canvasHeight = imgHeight;
						}

						let ocanvas = new OffscreenCanvas(imgWidth, imgHeight);
						let octx = ocanvas.getContext("2d", { willReadFrequently: true });
						octx.drawImage(image, 0, 0);
						this.lastCanvas = ocanvas;


						canvas.width = canvasWidth;
						canvas.height = canvasHeight;

						// ctx.drawImage(image, 0, 0);

						this.lastWidth = canvasWidth;
						this.lastHeight = canvasHeight;
						this.lastBrightness = 0;
						this.lastContrast = 0;

						this.imageLoading = false;
						this.drawing = false;
						this.zoomed = true;
						this.drawImage();
					})
					image.crossOrigin = "Anonymous";
					image.src = this.apiOrigin + '/images/radiology/' + this.showImage;
				}, 1)

			}
		},
		
		changeTheme(){
			this.theme = this.theme=='night' ? 'day' : 'night'
			localStorage.theme = this.theme;
		},

		updateLastActive() {
			this.lastActive = new Date().getTime();
		},
		setupSocEvents() {
			{
				let fd = {
					state: this.state,
					reportId: this.reportId
				}
				soc.emit("startReporting", fd);


				this.waitingSince = new Date().getTime();
			}
		},

		onReport(d) {
			console.log("on report: ", d);

			let alreadyLoaded = false;
			if (d.report.id == this.report?.id) alreadyLoaded = true;


			this.state = d.state;

			this.reportId = d.report.id;
			this.report = d.report;

			this.template = null;

			if (!alreadyLoaded) {
				this.editorData = this.report.report ? this.report.report : '';
				this.impression = this.report.impression ? this.report.impression : '';

				if (!this.editorData && !this.impression && this.state == 'reporting') {
					soc.emit("draftReport", {
						reportId: this.report.id,
						report: ' ',
						impression: ''
					});
					soc.once("draftReport", d => {
						console.log("Silent draft: ", d);
					});
				}

				notiAudio.play();
			}

			this.allImages = this.report.images.split(",");
			this.showImage = this.allImages[0];
			setTimeout(() => {
				this.zoomed = true;
				this.drawImage();
			}, 1);

			if (this.report.status == 'review') {
				this.reportType = 'review'
			} else {
				if (this.report.assign_type == 'all') {
					this.reportType = 'open';
				} else {
					this.reportType = 'direct';
				}
			}

			this.historyAudioUrl = '';
			this.reviewAudioUrl = '';

			if (d.history_audio_file) {
				this.historyAudioUrl = this.apiOrigin + "/" + 'images/radiology/' + d.history_audio_file;
			}
			if (d.review_audio_file) {
				this.reviewAudioUrl = this.apiOrigin + "/" + 'images/radiology/' + d.review_audio_file;
			}

			this.notes = this.report.notes ? this.report.notes : '';
			this.showNotesEditor = false;

			if (this.state == 'accepting') {
				this.acceptTill = d.acceptTill;
			}

			this.reportTill = d.reportTill;
		},

		acceptReport() {
			soc.emit("acceptReport", {
				reportId: this.reportId,
				notes: this.showNotesEditor ? this.notes : ''
			})
		},
		releaseReport() {
			soc.emit("releaseReport", {
				reportId: this.reportId
			})
		},

		onUpdateStats(d) {
			console.log("Update Stats", d);

			this.openPoolCount = d.open;
			this.directPoolCount = d.direct;
			this.reviewPoolCount = d.review;
			this.reported = d.reported;
		},
		onLimitReached(limit) {
			this.limit = limit;
			this.limitReached = true;
		},
		onStateChange(d) {
			this.state = d.state;

			console.log("On state change: ", d);

			if (d.state == 'inactive') {
				this.report = null;
				this.reportId = 0;
			} else if (d.state == 'waiting') {
				this.waitingSince = new Date().getTime();
			} else if (d.state == 'reporting') {
				if (d.reportTill) this.reportTill = d.reportTill;

				if (!this.editorData && !this.impression && this.state == 'reporting') {
					soc.emit("draftReport", {
						reportId: this.report.id,
						report: ' ',
						impression: ''
					});
					soc.once("draftReport", d => {
						console.log("Silent draft: ", d);
					});
				}

			}
		},
		onIncreaseTimer(d) {
			this.timerIncreased = false;

			if (d.state == 'accepting') {
				this.acceptTill = d.acceptTill;
			} else if (d.state == 'reporting') {
				this.reportTill = d.reportTill;
			}

			console.log("increaseTimer", d);
		},

		makeMeAvailable() {
			soc.emit('makeMeAvailable', true);
		},

		updateWaitingText() {
			{
				let now = new Date().getTime();

				let diff = now - this.waitingSince;
				diff = Math.round(diff / 1000);

				let hours = Math.floor(diff / 60 / 60);
				diff = diff - hours * 60 * 60;
				hours = String(hours);
				if (hours.length == 1) hours = "0" + hours;

				let minutes = Math.floor(diff / 60);
				diff = diff - minutes * 60;
				minutes = String(minutes)
				if (minutes.length == 1) minutes = "0" + minutes;

				let seconds = String(diff);
				if (seconds.length == 1) seconds = "0" + seconds;

				this.waitingText = hours + ' : ' + minutes + ' : ' + seconds;
			}
			document.title = "Teleradio";

			if (this.state == 'accepting' && this.acceptTill) {
				let now = new Date().getTime();

				let diff = this.acceptTill - now;
				diff = Math.round(diff / 1000);

				if (diff < 0) diff = 0;

				let minutes = Math.floor(diff / 60);
				diff = diff - minutes * 60;
				minutes = String(minutes)
				if (minutes.length == 1) minutes = "0" + minutes;

				let seconds = String(diff);
				if (seconds.length == 1) seconds = "0" + seconds;

				this.acceptTimeoutText = minutes + ' : ' + seconds;


				if (this.acceptTimeoutText < '00 : 10' && !this.timerIncreased) {
					let lad = new Date().getTime() - this.lastActive;
					if (lad < 60 * 1000) {
						this.timerIncreased = true;
						soc.emit('increaseTimer', true);
					}
				}

				document.title = diff % 2 == 0 ? "(" + seconds + ") New Case" : 'Attention Please...';
			}

			if (this.state == 'reporting' && this.reportTill) {
				let now = new Date().getTime();

				let diff = this.reportTill - now;
				diff = Math.round(diff / 1000);

				if (diff < 0) diff = 0;

				let minutes = Math.floor(diff / 60);
				diff = diff - minutes * 60;
				minutes = String(minutes)
				if (minutes.length == 1) minutes = "0" + minutes;

				let seconds = String(diff);
				if (seconds.length == 1) seconds = "0" + seconds;

				this.reportTimeoutText = minutes + ' : ' + seconds;

				if (this.reportTimeoutText < '00 : 10' && !this.timerIncreased) {
					let lad = new Date().getTime() - this.lastActive;
					if (lad < 60 * 1000) {

						console.log(this.reportTimeoutText, '00 : 10', this.reportTimeoutText < '00 : 10');

						this.timerIncreased = true;
						soc.emit('increaseTimer', true);
					}
				}
			}

		},



		onReady(editor) {
			editor.ui.getEditableElement().parentElement.insertBefore(
				editor.ui.view.toolbar.element,
				editor.ui.getEditableElement()
			);
		},
		searchTemplates(e) {
			this.filteredTemplates = this.templates.filter(t => t.title.toLowerCase().indexOf(e.query.toLowerCase()) != -1)
		},
		selectTemplate() {
			if (this.template){
				this.editorData = this.template.content ? this.template.content : '';
				this.impression = this.template.impression ? this.template.impression : '';
			}
		},
		async draftReport() {
			soc.emit("draftReport", {
				reportId: this.report.id,
				report: this.editorData,
				impression: this.impression
			});
			soc.once("draftReport", d => {
				if (d.success) {
					this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Report drafted successfully', life: 3000 });
				} else {
					this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong. Please try again later.', life: 3000 });
				}
			})
		},
		async publishReport() {
			if (this.editorData.trim().length < 20) {
				return this.$toast.add({ severity: 'error', summary: 'Report Too Short', detail: 'This report is too short. Please type some more to publish this report.', life: 3000 });
			}

			soc.emit("publishReport", {
				reportId: this.report.id,
				report: this.editorData,
				impression: this.impression
			});
			soc.once("publishReport", d => {
				if (d.success) {
					this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Report published successfully', life: 3000 });

					this.$router.push("/radiologist/report/0");
					this.state = 'waiting';
					this.waitingSince = new Date().getTime();
				} else {
					this.$toast.add({ severity: 'error', summary: 'Error', detail: 'Something went wrong. Please try again later.', life: 3000 });
				}
			});
		},

		saveNotes() {
			if (this.notes.trim().length < 5) {
				return this.$toast.add({ severity: 'error', summary: 'Notes Too Short', detail: 'Notes for this report is too short. Please type some more to send the notes.', life: 3000 });
			}
			soc.emit("saveNotes", {
				reportId: this.reportId,
				notes: this.notes,
			})
		},

		async togglePresent() {
			await this.post('radiologist/toggle-present', {
				isPresent: this.isPresent
			})
		},

		async saveTemplate(){
			if(!this.editorData) return this.$toast.add({ severity: 'info', summary: 'No Template Data', detail: 'Please enter some template data', life: 3000 });
			let title = prompt("Template Title:")
			if(!title) return this.$toast.add({ severity: 'info', summary: 'Title Required', detail: 'Can not save template without a title', life: 3000 });
			let fd = {
				title,
				content: this.editorData,
				impression: this.impression
			}
			await this.post("radiologist/put-template", fd)
			this.templates = await this.get("radiologist/load-templates");
			return this.$toast.add({ severity: 'succss', summary: 'Success', detail: 'Your new template is ready to use', life: 3000 });
		}

	},
	async mounted() {
		setTimeout(() => {
			soc.on('connect', this.setupSocEvents)

			soc.on("report", this.onReport);
			soc.on("timeout", this.onTimeout);
			soc.on("updateStats", this.onUpdateStats);
			soc.on("limitReached", this.onLimitReached);
			soc.on("stateChange", this.onStateChange);
			soc.on("increaseTimer", this.onIncreaseTimer);

			this.setupSocEvents();
		}, 0)

		document.body.addEventListener('mousemove', this.updateLastActive);
		document.body.addEventListener('keyup', this.updateLastActive);
		document.body.addEventListener('mouseup', this.onMouseUp);
		document.body.addEventListener('mousemove', this.onMouseMove);
		

		this.waitingUpdateInterval = setInterval(this.updateWaitingText, 1000)

		this.reportId = Number(this.$route.params['report_id']);


		this.get('radiologist/get-is-present').then(res => {
			this.isPresent = res.isPresent;
		})

		this.templates = await this.get("radiologist/load-templates");

		if(localStorage.theme) this.theme =  localStorage.theme;
	},
	unmounted() {
		soc.emit("exitReportingPage", true);

		soc.off('connect', this.onConnect);
		soc.off('report');
		soc.off('timeout');
		soc.off('updateStats');
		soc.off('limitReached')
		soc.off('stateChange')
		soc.off("increaseTimer");

		document.body.removeEventListener('mousemove', this.updateLastActive);
		document.body.removeEventListener('keyup', this.updateLastActive);
		document.body.removeEventListener('mouseup', this.onMouseUp);
		document.body.removeEventListener('mousemove', this.onMouseMove);

		clearInterval(this.waitingUpdateInterval);

		document.title = "Teleradio";

	},
}
</script>
<style scoped>
canvas {
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
</style>